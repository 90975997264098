import { z } from 'zod';
import { requiredUuidValidation } from '../../requiredUuidValidation';
import { zodEnumFromObjKeys } from '../../../zodEnumFromObjKeys';

export const createBankTransferBasicDetailsStepSchema = z.object({
    accountId: requiredUuidValidation,
    definitionId: requiredUuidValidation,
    holderId: requiredUuidValidation,
    tenantId: requiredUuidValidation,
});

export const createBankTransferBasicDetailsStepSchemaKeys = zodEnumFromObjKeys(
    createBankTransferBasicDetailsStepSchema.shape
).Enum;
