import { z } from 'zod';
import validationLang from '../../lang';
import { externalReferenceRegex } from '../externalReferenceRegex';
import { LABEL_MAX_LENGTH } from '../constants';

const externalReferenceSchema = z
    .string()
    .trim()
    .regex(new RegExp(externalReferenceRegex), validationLang.INVALID_REGEX)
    .max(LABEL_MAX_LENGTH, validationLang.GET_MAX_LENGTH(LABEL_MAX_LENGTH))
    .optional()
    .or(z.literal(''))
    .nullable();

export const externalReferenceStepSchema = z.object({
    externalReference: externalReferenceSchema,
});
