import { z } from 'zod';
import { zodEnumFromObjKeys } from '../../../zodEnumFromObjKeys';
import { requiredUuidValidation } from '../../requiredUuidValidation';

export const payA2ASchema = z.object({
    amount: z.string().trim(),
    currency: z.string().trim(),
    recipientId: requiredUuidValidation,
});

export const payA2ASchemaKeys = zodEnumFromObjKeys(payA2ASchema.shape).Enum;
