/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiError {
  location?: string | null;
  message: string;
  reason: ErrorApiErrorReason;
}

export type ContinuationToken = string;

/** ISO 4217 alphabetic currency code. */
export type Currency = string;

/** String with max 50 letters, digits, _ or - */
export type ExternalReference = string;

export type Iban = string;

export type IdempotencyHeader = string;

/** @example {"key":"val"} */
export type JsonNotes = any;

export type LegalForm = string;

/** Represents a monetary value of a specific currency. */
export interface Monetary {
  /** fixed point decimal. decimal places as defined by currency */
  amount: string;
  /** ISO 4217 alphabetic currency code */
  currency: string;
}

export type NaceCode = string;

/**
 * Pagination size as expressed as a 64 bit number.
 * @format int64
 * @min 1
 * @max 100
 */
export type PaginationSize = number;

export interface PhoneNumber {
  /**
   * @format uint32
   * @min 1
   */
  countryCode: number;
  /** RFC 3966 */
  number: string;
}

export interface A2ATransfersCreateHolder {
  /** tenant id of this entity */
  tenantId: string;
  /** identity ids of this entity */
  identities: string[];
  /** a label for this entity */
  label: string;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** external reference for this entity */
  externalReference?: string | null;
}

export interface A2ATransfersCreateInstrument {
  /** account id of this entity */
  accountId: string;
  /** a2a transfer holder id of this entity */
  holderId?: string | null;
  /** a2a transfer holder id of this entity */
  a2AHolderId?: string | null;
  /** tenant id of this entity */
  tenantId: string;
  /** definition id of this entity */
  definitionId: string;
  /** External reference */
  externalReference?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface A2ATransfersCreatePayment {
  /** amount of this payment */
  amount: Monetary;
  /** a2a payment recipient instrument id */
  recipientId: string;
  /** description of this payment */
  description?: string | null;
  /** External reference */
  externalReference?: string | null;
  /** json notes */
  notes?: JsonNotes | null;
}

export interface A2ATransfersHolder {
  /** the id this entity */
  id: string;
  /** tenant id of this entity */
  tenantId: string;
  /** identity ids of this entity */
  identities: string[];
  /** label of this entity */
  label: string;
  /** timestamps for this entity */
  datetimes: A2ATransfersHolderDatetimes;
  /** json notes */
  notes?: JsonNotes | null;
  /** external reference for this entity */
  externalReference?: string | null;
  /** status of the entity */
  status: A2ATransfersHolderStatus;
}

export interface A2ATransfersHolderDatetimes {
  /** time of the entity creation */
  created: string;
}

export enum A2ATransfersHolderOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum A2ATransfersHolderStatus {
  UNACTIVATED = "UNACTIVATED",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED = "TERMINATED",
}

export interface A2ATransfersInstrument {
  /** the id this entity */
  id: string;
  /** account id of this entity */
  accountId: string;
  /** a2a transfer holder id of this entity */
  holderId: string;
  /** a2a transfer holder id of this entity */
  a2AHolderId: string;
  /** tenant id of this entity */
  tenantId: string;
  /** definition id of this entity */
  definitionId: string;
  /** External reference */
  externalReference?: string | null;
  /** timestamps for this entity */
  datetimes: A2ATransfersInstrumentDatetimes;
  /** json notes */
  notes?: JsonNotes | null;
  /** status of this entity */
  status: A2ATransfersInstrumentStatus;
}

export interface A2ATransfersInstrumentDatetimes {
  /** time of the entity creation */
  created: string;
}

export enum A2ATransfersInstrumentOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum A2ATransfersInstrumentStatus {
  UNACTIVATED = "UNACTIVATED",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED_OBSOLETE = "TERMINATED_OBSOLETE",
  TERMINATED = "TERMINATED",
}

export interface A2ATransfersNewInstrumentStatus {
  /** the id this entity */
  id: string;
  /** the id of this update */
  updateId: string;
  /** timestamps for this entity */
  datetimes: A2ATransfersUpdatedInstrumentDatetimes;
  /** json notes */
  notes?: JsonNotes | null;
  /** status of this entity */
  status: A2ATransfersInstrumentStatus;
}

export interface A2ATransfersPayment {
  /** the id this entity */
  id: string;
  /** a2a payment sender instrument id */
  senderId: string;
  /** a2a payment recipient instrument id */
  recipientId: string;
  /** amount of this payment */
  amount: Monetary;
  /** External reference */
  externalReference?: string | null;
  /** timestamps for this entity */
  datetimes: A2ATransfersPaymentDatetimes;
  /** description of this payment */
  description?: string | null;
  /** json notes */
  notes?: JsonNotes | null;
}

export interface A2ATransfersPaymentDatetimes {
  /** time of the entity creation */
  created: string;
}

export interface A2ATransfersPaymentEvent {
  /** the id this entity */
  id: string;
  /** a2a payment id */
  paymentId: string;
  /** timestamps for this entity */
  datetimes: A2ATransfersPaymentEventDatetimes;
  /** a2a payment kind */
  kind: A2ATransfersPaymentEventKind;
}

export interface A2ATransfersPaymentEventDatetimes {
  /** time of the entity creation */
  created: string;
}

export enum A2ATransfersPaymentEventError {
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  LOCKED = "LOCKED",
  INTERNAL = "INTERNAL",
}

export type A2ATransfersPaymentEventKind =
  | "SCHEDULED"
  | "PENDING"
  | "EXECUTED"
  | "COMPLETED"
  | "DISABLED"
  | {
      ERROR: A2ATransfersPaymentEventError;
    };

export enum A2ATransfersPaymentEventKindFilter {
  SCHEDULED = "SCHEDULED",
  PENDING = "PENDING",
  EXECUTED = "EXECUTED",
  COMPLETED = "COMPLETED",
  DISABLED = "DISABLED",
  ERROR = "ERROR",
}

export enum A2ATransfersPaymentEventOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum A2ATransfersPaymentOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum A2ATransfersSetInstrumentStatus {
  TERMINATED = "TERMINATED",
}

export interface A2ATransfersTransaction {
  /** the id this entity */
  id: string;
  /** a2a payment id */
  paymentId: string;
  /** party of this transaction */
  party: A2ATransfersTransactionParty;
  /** counterparty of this transaction */
  counterparty: A2ATransfersTransactionParty;
  /** a2a party isntrument id (DEPRECATED: use party.instrument_id instead) */
  instrumentId: string;
  /** amount of this transaction */
  amount: Monetary;
  /** timestamps for this entity */
  datetimes: A2ATransfersTransactionDatetimes;
  /** a2a transaction kind */
  kind: A2ATransfersTransactionKind;
  /** External reference */
  externalReference?: string | null;
  /** description of this payment */
  description?: string | null;
}

export interface A2ATransfersTransactionDatetimes {
  /** time of the entity creation */
  created: string;
}

export enum A2ATransfersTransactionKind {
  Pull = "Pull",
  Push = "Push",
}

export enum A2ATransfersTransactionOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface A2ATransfersTransactionParty {
  /** account id of this party */
  accountId: string;
  /** instrument id of this party */
  instrumentId: string;
}

export interface A2ATransfersUpdateInstrumentStatus {
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** status to set */
  status: A2ATransfersSetInstrumentStatus;
}

export interface A2ATransfersUpdatedInstrumentDatetimes {
  /** time of the entity update */
  updated: string;
}

export interface AccountsAccount {
  /** the id this entity */
  id: string;
  /** account holder id of this entity */
  accountHolderId: string;
  /** tenant id of this entity */
  tenantId: string;
  /** name of this entity (use label instead) */
  accountName: string;
  /** timestamps for this entity */
  datetimes: AccountsAccountDatetimes;
  /** definition id of this entity */
  definitionId: string;
  /** external reference of this entity */
  externalReference?: string | null;
  /** json notes */
  notes?: JsonNotes | null;
  /** status of this entity */
  status: AccountsAccountStatus;
  /** balances of this entity */
  balances: AccountsAccountBalances;
  /** a label for this entity */
  label: string;
  /** a list of tags for this entity */
  tags: string[];
}

export interface AccountsAccountBalances {
  /** available for spending */
  available: Monetary;
  /** amount is held and cannot be spent */
  hold: Monetary;
  /** sum of available and hold amount */
  total: Monetary;
}

export interface AccountsAccountDatetimes {
  /** time of the entity creation */
  created: string;
  /** time of the last entity update */
  lastUpdated: string;
  /** time of the last entity update */
  updated: string;
}

export interface AccountsAccountHolder {
  /** the id this entity */
  id: string;
  /** account holder id of this entity */
  identities: string[];
  /** tenant id of this entity */
  tenantId: string;
  /** label of this entity */
  label?: string | null;
  /** timestamps for this entity */
  datetimes: AccountsAccountHolderDatetimes;
  /** external reference of this entity */
  externalReference?: string | null;
  /** json notes */
  notes?: JsonNotes | null;
}

export interface AccountsAccountHolderDatetimes {
  /** time of the entity creation */
  created: string;
  /** time of the last entity update */
  updated: string;
}

export enum AccountsAccountHolderOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum AccountsAccountOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum AccountsAccountStatus {
  UNACTIVATED = "UNACTIVATED",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED = "TERMINATED",
}

export enum AccountsAccountStatusRequest {
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED = "TERMINATED",
}

export interface AccountsAccountTagsHistoryEntry {
  /** the id this update */
  updateId: string;
  /** the account id that was updated */
  accountId: string;
  /** update timestamp for this entity */
  datetimes: AccountsUpdateDatetimes;
  /** tags assigned to this account */
  tags: string[];
  /** json notes */
  notes?: JsonNotes | null;
}

export interface AccountsAddTagsList {
  /** list of tags */
  tags: string[];
}

export interface AccountsCreateAccount {
  /** account holder id for this entity */
  accountHolderId: string;
  /** tenant id for this entity */
  tenantId: string;
  /** definition id for this entity */
  definitionId: string;
  /** name for this entity (use label instead) */
  accountName?: string | null;
  /** external reference for this entity */
  externalReference?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** a label for this entity */
  label?: string | null;
  /** a list of tags for this entity */
  tags?: string[] | null;
}

export interface AccountsCreateAccountHolder {
  /** identity id for this entity */
  identityId: string;
  /** tenant id for this entity */
  tenantId: string;
  /** a label for this entity */
  label?: string | null;
  /** external reference for this entity */
  externalReference?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export enum AccountsPaymentMethod {
  BANK_TRANSFER = "BANK_TRANSFER",
  CARD = "CARD",
  A2ATRANSFER = "A2A_TRANSFER",
}

export interface AccountsRemoveTagsList {
  /** list of tags */
  tags: string[];
}

export interface AccountsSetTagsList {
  /** list of tags */
  tags: string[];
}

export type AccountsTagUpdateKind =
  | ({
      kind: "ADD";
    } & AccountsAddTagsList)
  | ({
      kind: "SET";
    } & AccountsSetTagsList)
  | ({
      kind: "REMOVE";
    } & AccountsRemoveTagsList);

export interface AccountsTransaction {
  /** the id this entity */
  id: string;
  /** The account this transaction happened on */
  accountId: string;
  /** amount of this transaction */
  amount: Monetary;
  /** balances of this transaction */
  balances: AccountsTransactionBalances;
  /** timestamps of this transaction */
  datetimes: AccountsTransactionDatetimes;
  /** source of this transaction */
  source: AccountsTransactionSource;
}

export interface AccountsTransactionBalances {
  /** Total balance after this transaction */
  totalAfter: Monetary;
}

export interface AccountsTransactionDatetimes {
  /** when the transaction was initiated */
  initiated?: string | null;
  /** when the transaction was valued */
  valueDated: string;
}

export enum AccountsTransactionOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface AccountsTransactionSource {
  /** source instrument id */
  instrumentId: string;
  /** payment method of this transaction */
  method: AccountsPaymentMethod;
  /** source transaction */
  transactionId: string;
}

export interface AccountsUpdateAccountStatusRequest {
  /** status you are trying to set */
  status: AccountsAccountStatusRequest;
  /** json notes */
  notes?: JsonNotes | null;
}

export interface AccountsUpdateAccountStatusResponse {
  /** account id that was changed */
  accountId: string;
  /** status of this entity */
  status: AccountsAccountStatus;
  /** json notes */
  notes?: JsonNotes | null;
  /** id of the update */
  updateId: string;
}

export interface AccountsUpdateAccountTagsRequest {
  updateKind: AccountsTagUpdateKind;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface AccountsUpdateDatetimes {
  /** time of entity update */
  updated: string;
}

export interface ActorsActor {
  /** the id this entity */
  id: string;
  /** label of actor */
  label?: string | null;
  /** roles of actor */
  roles: string[];
  /** timestamps for this entity */
  datetimes: ActorsDatetimes;
  /** tenant id of actor */
  tenantId: string;
  /** status of actor */
  status: ActorsStatus;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface ActorsActorLabelUpdate {
  /** the id of this update */
  id: string;
  /** the actor id this update belongs to */
  actorId: string;
  /** label assigned to this actor */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** update timestamp for this entity */
  datetimes: ActorsUpdateDatetimes;
}

export enum ActorsActorOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface ActorsAddRolesList {
  /** list of role ids */
  roleIds: string[];
}

export interface ActorsCreateActor {
  /** the tenant id at which this actor should be created */
  tenantId: string;
  /** roles of actor */
  roles?: string[] | null;
  /** a label for this actor */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface ActorsDatetimes {
  /** time of the entity creation */
  created: string;
}

export interface ActorsImpersonation {
  /** Permission that enables this impersonation */
  permissionId: string;
  /** Actor that can be impersonated */
  actorId: string;
  /** Label of the actor */
  actorLabel?: string | null;
  /** Tenant id of the actor that can be impersonated */
  tenantId: string;
  /** Label of the tenant of the actor that can be impersonated */
  tenantLabel: string;
}

export enum ActorsImpersonationsOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface ActorsRemoveRolesList {
  /** list of role ids */
  roleIds: string[];
}

export interface ActorsSetRolesList {
  /** list of role ids */
  roleIds: string[];
}

export enum ActorsStatus {
  PRIMARY_ACTOR = "PRIMARY_ACTOR",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
}

export interface ActorsUpdateActorLabelRequest {
  /** label assigned to this actor */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface ActorsUpdateActorRolesRequest {
  updateKind: ActorsUpdateKind;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface ActorsUpdateActorRolesResponse {
  /** @format uuid */
  updateId: string;
  /** roles assigned to this actor */
  roleIds: string[];
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** update timestamp for this entity */
  datetimes: ActorsUpdateDatetimes;
}

export interface ActorsUpdateDatetimes {
  /** time of entity update */
  updated: string;
}

export type ActorsUpdateKind =
  | ({
      kind: "ADD";
    } & ActorsAddRolesList)
  | ({
      kind: "SET";
    } & ActorsSetRolesList)
  | ({
      kind: "REMOVE";
    } & ActorsRemoveRolesList);

export interface BankTransferBankTransferInstrument {
  /** Id of the account */
  accountId: string;
  /** definition_id of the instrument */
  definitionId: string;
  /** holder_id of the instrument */
  holderId: string;
  /** tenant_id of the instrument */
  tenantId: string;
  /** external_reference */
  externalReference?: string | null;
  /** MAX 150 characters (including white spaces). Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Id of the bank transfer instrument */
  id: string;
  /** date times */
  datetimes: BankTransferDatetimes;
  /** Routing information of that payment instrument. */
  routingInfos: BankTransferRoutingInfo[];
}

export enum BankTransferBankTransferInstrumentOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface BankTransferBankTransferTransaction {
  /** Id of the bank transfer instrument */
  bankTransferId?: string | null;
  /** amount of the transaction */
  amount: Monetary;
  /** date times */
  datetimes: BankTransferDatetimesOptional;
  /** Id of the bank transfer instrument */
  id: string;
  /** Type of the transaction */
  kind: BankTransferTransactionKind;
  /** Payment Details */
  paymentDetails: string;
  /** Id of the payment initiated on our platform. Outgoing money */
  paymentId?: string | null;
  /** Id of the payment instance initiated on our platform. Outgoing money */
  paymentInstanceId?: string | null;
  /** Id of the transaction that this transaction reverses. */
  reversalOf?: string | null;
  /** Payment system specific information. */
  paymentSystemSpecific: BankTransferBankTransferTransactionPaymentSystemSpecific;
}

export interface BankTransferBankTransferTransactionPartySepa {
  /** address line 1 */
  addressLine1?: string | null;
  /** address line 2 */
  addressLine2?: string | null;
  /** BIC */
  bic?: string | null;
  /** country */
  country?: string | null;
  /** IBAN */
  iban: Iban;
  /** name */
  name?: string | null;
}

export type BankTransferBankTransferTransactionPaymentSystemSpecific = {
  Sepa: BankTransferBankTransferTransactionSepaSpecific;
};

export enum BankTransferBankTransferTransactionSepaKind {
  DIRECT_DEBIT = "DIRECT_DEBIT",
  CREDIT_TRANSFER = "CREDIT_TRANSFER",
  INSTANT_CREDIT_TRANSFER = "INSTANT_CREDIT_TRANSFER",
}

export interface BankTransferBankTransferTransactionSepaSpecific {
  /** Type of the SEPA transaction */
  kind: BankTransferBankTransferTransactionSepaKind;
  /** Counterparty of the transaction */
  counterparty: BankTransferBankTransferTransactionPartySepa;
  /** Party of the transaction */
  party: BankTransferBankTransferTransactionPartySepa;
}

export interface BankTransferDatetimes {
  /** time of creation of the payment */
  created: string;
}

export interface BankTransferDatetimesOptional {
  /** time of creation of the payment */
  created?: string | null;
}

export interface BankTransferHolder {
  /** Identities ids that are connected to the holder. For banking this should be one */
  identities: string[];
  /** User-given label for the holder. E.g. 'My Holder 1' */
  label: string;
  /** MAX 150 characters (including white spaces). Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** tenant_id of the instrument */
  tenantId: string;
  /** external_reference */
  externalReference?: string | null;
  /** date times */
  datetimes: BankTransferDatetimes;
  /** Id of the bank transfer instrument */
  id: string;
}

export enum BankTransferHolderOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface BankTransferInputBankTransferInstrument {
  /** Id of the account */
  accountId: string;
  /** definition_id of the instrument */
  definitionId: string;
  /** holder_id of the instrument */
  holderId: string;
  /** tenant_id of the instrument */
  tenantId: string;
  /** external_reference */
  externalReference?: string | null;
  /** MAX 150 characters (including white spaces). Free form JSON object with custom information */
  notes?: JsonNotes | null;
}

export interface BankTransferInputHolder {
  /** Identities ids that are connected to the holder. For banking this should be one */
  identities: string[];
  /** User-given label for the holder. E.g. 'My Holder 1' */
  label: string;
  /** MAX 150 characters (including white spaces). Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** tenant_id of the instrument */
  tenantId: string;
  /** external_reference */
  externalReference?: string | null;
}

export interface BankTransferInputPayment {
  /** receiver of the money */
  receiver: BankTransferInputReceiver;
  /** Description of the payment */
  description: string;
  /** amount */
  senderAmount: Monetary;
  /** external_reference */
  externalReference?: string | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
}

export interface BankTransferInputReceiver {
  /** iban of the receiver of the money */
  iban: Iban;
  /** name of the receiver of the money */
  name: string;
}

export interface BankTransferPayment {
  /** id of the payment */
  id: string;
  /** amount */
  senderAmount: Monetary;
  /** external_reference */
  externalReference?: string | null;
  /** date times */
  datetimes: BankTransferDatetimes;
  /** receiver of the money */
  receiver: BankTransferReceiver;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Description of the payment */
  description?: string | null;
}

export interface BankTransferPaymentEvent {
  /** Id of this event */
  id: string;
  /** Id of the payment of this event */
  paymentId: string;
  /** Id of the payment instance of this event */
  paymentInstanceId: string;
  /** Id of the payment instrument used for event */
  bankTransferId: string;
  /** Id of the transaction that this event caused. This is optional beacuse there can be no transaction for a payment yet. */
  transactionId?: string | null;
  /** Type of the event */
  kind: BankTransferPaymentEventStatus;
  /** datetimes of the event */
  datetimes: BankTransferDatetimes;
}

export enum BankTransferPaymentEventOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum BankTransferPaymentEventStatus {
  SCHEDULED = "SCHEDULED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  DISABLED = "DISABLED",
  EXECUTED = "EXECUTED",
  FAILED_MISSING_FUNDS = "FAILED_MISSING_FUNDS",
  FAILED = "FAILED",
  RECONCILED = "RECONCILED",
  REVERSED = "REVERSED",
}

export enum BankTransferPaymentOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface BankTransferReceiver {
  /** iban of the receiver of the money */
  iban: Iban;
  /** name of the receiver of the money */
  name: string;
}

export interface BankTransferRoutingInfo {
  /** iban */
  iban: Iban;
  /** bic */
  bic: string;
}

export enum BankTransferTransactionKind {
  SEPA = "SEPA",
  CROSSBORDER = "CROSSBORDER",
  TARGET2 = "TARGET2",
  INTERNAL = "INTERNAL",
}

export enum BankTransferTransactionOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface CardsAccessToken {
  /** @format uuid */
  id: string;
  datetimes: CardsVaultAccessTokenDateTimes;
  token: string;
}

export interface CardsAppleProvisioningRequest {
  /** Unique identifier for this provisioning request */
  id: string;
  /** Unique identifier for the card that the provisioning request is for */
  cardId: string;
  wallet: CardsAppleWallet;
  app: CardsAppleWalletApp;
  /** When the request was created */
  datetimes: CardsWalletProvisioningRequestDateTimes;
  /** Structure with data required for the tokenization request */
  receipt: CardsAppleWalletReceipt;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsAppleWallet {
  /** B64 encoded leaf and sub-CA certificates provided by Apple. The first element of the array should be the leaf certificate, followed by the sub-CA. */
  certificates: string[];
  /** One-time-use nonce provided by Apple B64 encoded */
  nonce: string;
  /** Signature to the nonce, provided by Apple */
  nonceSignature: string;
  /** Type of the device the provisioning request is for */
  deviceType: CardsWalletDeviceTypeProvisioning;
}

export interface CardsAppleWalletApp {
  /** Version of the application requesting the provisioning request */
  version: string;
  /** Version of the application requesting the provisioning request */
  name: string;
}

export interface CardsAppleWalletReceipt {
  /** Payload encrypted with a shared key derived from the Apple Public Certificates and the generated ephemeral private key */
  encryptedPassData: string;
  /** Cryptographic one-time passcode conforming to the payment network operator or service provider specifications */
  activationData: string;
  /** Ephemeral public key used for the provisioning attempt. */
  ephemeralPublicKey: string;
}

export interface CardsAppleWebPushProvisioningRequest {
  /** Unique identifier for this provisioning request */
  id: string;
  /** Unique identifier for the card that the provisioning request is for */
  cardId: string;
  /** When the request was created */
  datetimes: CardsWalletProvisioningRequestDateTimes;
  /** Structure with data required for the tokenization request */
  receipt: CardsAppleWebPushWalletReceipt;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsAppleWebPushWalletReceipt {
  /** JSON Web Signature (JWS) */
  jws: CardsAppleWebPushWalletReceiptJws;
  /** Provider-related fields */
  provider: CardsAppleWebPushWalletReceiptProvider;
}

export interface CardsAppleWebPushWalletReceiptJws {
  /** Header of the JSON Web Signature (JWS) public key of the key pair used to generate the signature */
  header: CardsAppleWebPushWalletReceiptJwsHeader;
  /** Contains header parameters that are integrity-protected by the JSON Web Signature (JWS) */
  protected: string;
  /** Message payload the JSON Web Signature (JWS) */
  payload: string;
  /** JSON Web Signature (JWS) */
  signature: string;
}

export interface CardsAppleWebPushWalletReceiptJwsHeader {
  /** Unique identifier of the JSON Web Signature (JWS) public key of the key pair used to generate the signature */
  kid: string;
}

export interface CardsAppleWebPushWalletReceiptProvider {
  /** Unique reference associated with the digital wallet token */
  reference: string;
}

export interface CardsAppliedVelocityControlListEntry {
  /** id of the velocity control */
  id: string;
  /** Label to describe the velocity control */
  label: string;
  /** The tenant to which this velocity control will belong */
  tenantId: string;
  /** The target of this velocity control. */
  target: CardsVelocityControlTarget;
  /** The scope according to which the velocity control is being applied. Currently there is only one kind of scope available. */
  scope: CardsVelocityControlScope;
  /** The velocity constraints that should be applied as part of this control. */
  velocity: CardsVelocityControlVelocity;
  /** The time window in which the velocity control will be applied. */
  window: CardsVelocityControlWindow;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Configuration parameters for the processing of velocity controls during authorizations. */
  config: CardsVelocityControlProcessingConfig;
  datetimes: CardsVelocityControlDateTimes;
  /** The current spending amounts when this control is applied to the target entity. */
  appliedVelocity: CardsAppliedVelocityControlVelocity;
  /** the configured windowing behavior applied to the current date and time. */
  appliedWindow: CardsAppliedVelocityControlWindow;
}

export interface CardsAppliedVelocityControlVelocity {
  /** The amount of money that has been spent in the current window. */
  amountSpent: Monetary;
  /** The amount of money that can be spent overall in the current window. (This effectively is the value in the window configuration.) */
  amountLimit: Monetary;
  /** The amount of money that still can be spent in the current window, before the control will start declining authorizations. */
  amountRemaining: Monetary;
}

export interface CardsAppliedVelocityControlWindow {
  /** The date and time at which the current window started. */
  startAt?: string | null;
  /** The date and time at which the current window will end. */
  endAt?: string | null;
}

export enum CardsAppliedVelocityControlsOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface CardsAuthorization {
  /** @format uuid */
  id: string;
  /** @format uuid */
  cardId: string;
  status: CardsAuthorizationStatus;
  amounts: CardsAuthorizationAmounts;
  datetimes: CardsAuthorizationDateTimes;
  source: CardsSource;
  merchant: CardsMerchant;
  wallet?: CardsWallet | null;
}

export interface CardsAuthorizationAmounts {
  /** Represents a monetary value of a specific currency. */
  approved: Monetary;
}

export interface CardsAuthorizationControlDateTimes {
  /** The authorization control will be active from this point in time. */
  effectiveFrom: string;
  /** The authorization control will no longer applied after this point in time. */
  effectiveUntil?: string | null;
  /** Timestamp at which this control was created. */
  created: string;
  /** Timestamp at which the control was last modified. */
  updated: string;
}

export enum CardsAuthorizationControlOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export type CardsAuthorizationControlTarget =
  | ({
      kind: "CARD";
    } & CardsAuthorizationControlTargetCard)
  | ({
      kind: "CARD_PRODUCT_DEFINITION";
    } & CardsAuthorizationControlTargetCardProductDefinition);

export enum CardsAuthorizationControlTargetKind {
  CARD = "CARD",
  CARD_PRODUCT_DEFINITION = "CARD_PRODUCT_DEFINITION",
}

export interface CardsAuthorizationDateTimes {
  /** @format date-time */
  initiated: string;
  /** @format date-time */
  lastUpdated: string;
}

export interface CardsAuthorizationEvent {
  /** @format uuid */
  id: string;
  /** @format uuid */
  authorizationId: string;
  /** @format uuid */
  cardId: string;
  kind: CardsAuthorizationEventKind;
  status: CardsAuthorizationEventStatus;
  amounts: CardsAuthorizationEventAmounts;
  datetimes: CardsAuthorizationEventDateTimes;
  outcomeReason?: CardsAuthorizationEventOutcomeReason | null;
  outcome?: CardsAuthorizationEventOutcome | null;
  currencyConversion: CardsAuthorizationEventCurrencyConversion;
  wallet?: CardsWallet | null;
}

export interface CardsAuthorizationEventAmounts {
  /** Approved amount for this authorization event. In the currency of the card. */
  approved: Monetary;
  /** Amount originally requested in the card currency. */
  requested: Monetary;
  /** Amount originally requested in the merchant currency. */
  requestedMerchant: Monetary;
  /** Amount received as cash. This amount is included in the above approved amount. */
  requestedCashback: Monetary;
}

export interface CardsAuthorizationEventCurrencyConversion {
  /** The conversion rate that was applied to the amounts. */
  conversionRate: string;
}

export interface CardsAuthorizationEventDateTimes {
  /** Time of the event at the source. */
  initiated: string;
  /** Time when the event is processed by the card issuer (Dock). */
  completed: string;
}

export enum CardsAuthorizationEventKind {
  CREATION = "CREATION",
  INCREASE = "INCREASE",
  ADVICE = "ADVICE",
  REVERSAL = "REVERSAL",
  EXPIRY = "EXPIRY",
}

export enum CardsAuthorizationEventOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export type CardsAuthorizationEventOutcome =
  | ({
      reason: "ACCOUNT_INSUFFICIENT_FUNDS";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "ADDRESS_VERIFICATION_SUCCESS";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "APPROVAL";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "AUTHORIZATION_PARTIALLY_APPROVED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_ACCOUNT_VERIFICATION_SUCCESS";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_ACTIVATION_CHECK_SUCCESS";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_ATM_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_CHANNEL_OTHER_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_CVV_LIMIT_EXCEEDED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_ECOM_TRANSACTION_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_EXPIRATION_MISMATCH";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_EXPIRED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_INVALID_CVV2";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_INCORRECT_PIN";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_LOCKED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_MCC_BLOCKED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_NETWORK_LOAD_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_NETWORK_RESPONSE";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_SPENDING_EXCEEDED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_TERMINATED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_TRANSACTION_ALREADY_REVERSED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_TRANSACTION_NOT_PERMITTED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_UNACTIVATED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "CARD_USAGE_COUNT_REACHED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_EXPIRED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_LOCKED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_NOT_ACTIVE";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_NOT_FOUND";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_NOT_PRESENT";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_PENDING";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_SUSPENDED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "WALLET_TOKEN_TERMINATED";
    } & CardsAuthorizationEventOutcomeNoDetails)
  | ({
      reason: "VELOCITY_CONTROL_DECLINED";
    } & CardsAuthorizationEventOutcomeVelocityControlDeclined);

export enum CardsAuthorizationEventOutcomeReason {
  ACCOUNT_INSUFFICIENT_FUNDS = "ACCOUNT_INSUFFICIENT_FUNDS",
  ADDRESS_VERIFICATION_SUCCESS = "ADDRESS_VERIFICATION_SUCCESS",
  APPROVAL = "APPROVAL",
  AUTHORIZATION_PARTIALLY_APPROVED = "AUTHORIZATION_PARTIALLY_APPROVED",
  CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED = "CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED",
  CARD_ACCOUNT_VERIFICATION_SUCCESS = "CARD_ACCOUNT_VERIFICATION_SUCCESS",
  CARD_ACTIVATION_CHECK_SUCCESS = "CARD_ACTIVATION_CHECK_SUCCESS",
  CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO = "CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO",
  CARD_ATM_NOT_ALLOWED = "CARD_ATM_NOT_ALLOWED",
  CARD_CHANNEL_OTHER_NOT_ALLOWED = "CARD_CHANNEL_OTHER_NOT_ALLOWED",
  CARD_CVV_LIMIT_EXCEEDED = "CARD_CVV_LIMIT_EXCEEDED",
  CARD_ECOM_TRANSACTION_NOT_ALLOWED = "CARD_ECOM_TRANSACTION_NOT_ALLOWED",
  CARD_EXPIRATION_MISMATCH = "CARD_EXPIRATION_MISMATCH",
  CARD_EXPIRED = "CARD_EXPIRED",
  CARD_INCORRECT_PIN = "CARD_INCORRECT_PIN",
  CARDINVALIDCVV2 = "CARD_INVALID_CVV2",
  CARD_LOCKED = "CARD_LOCKED",
  CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED = "CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED",
  CARD_MCC_BLOCKED = "CARD_MCC_BLOCKED",
  CARD_NETWORK_LOAD_NOT_ALLOWED = "CARD_NETWORK_LOAD_NOT_ALLOWED",
  CARD_NETWORK_RESPONSE = "CARD_NETWORK_RESPONSE",
  CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED = "CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED",
  CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED = "CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED",
  CARD_SPENDING_EXCEEDED = "CARD_SPENDING_EXCEEDED",
  CARD_TERMINATED = "CARD_TERMINATED",
  CARD_TRANSACTION_ALREADY_REVERSED = "CARD_TRANSACTION_ALREADY_REVERSED",
  CARD_TRANSACTION_NOT_PERMITTED = "CARD_TRANSACTION_NOT_PERMITTED",
  CARD_UNACTIVATED = "CARD_UNACTIVATED",
  CARD_USAGE_COUNT_REACHED = "CARD_USAGE_COUNT_REACHED",
  WALLET_TOKEN_EXPIRED = "WALLET_TOKEN_EXPIRED",
  WALLET_TOKEN_LOCKED = "WALLET_TOKEN_LOCKED",
  WALLET_TOKEN_NOT_ACTIVE = "WALLET_TOKEN_NOT_ACTIVE",
  WALLET_TOKEN_NOT_FOUND = "WALLET_TOKEN_NOT_FOUND",
  WALLET_TOKEN_NOT_PRESENT = "WALLET_TOKEN_NOT_PRESENT",
  WALLET_TOKEN_PENDING = "WALLET_TOKEN_PENDING",
  WALLET_TOKEN_SUSPENDED = "WALLET_TOKEN_SUSPENDED",
  WALLET_TOKEN_TERMINATED = "WALLET_TOKEN_TERMINATED",
  VELOCITY_CONTROL_DECLINED = "VELOCITY_CONTROL_DECLINED",
}

export enum CardsAuthorizationEventStatus {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
}

/** At least one value of each field has to match an incomming authorization for that authorzation to be included in the control. */
export interface CardsAuthorizationKindlessSelector {
  /** Specifies which channels should be considered by this control. */
  channels: CardsAuthorizationSelectorChannel[];
  /** Specifies which postal code patterns should be considered by this control. */
  postalCodes: string[];
  /** Specifies which city patterns should be considered by this control. */
  cities: string[];
  /** Specifies which countries should be considered by this control. */
  countries: string[];
}

export enum CardsAuthorizationOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

/** At least one value of each field has to match an incomming authorization for that authorzation to be included in the control. */
export interface CardsAuthorizationSelector {
  /** Specifies which channels should be considered by this control. */
  channels: CardsAuthorizationSelectorChannel[];
  /** Specifies which postal code patterns should be considered by this control. */
  postalCodes: string[];
  /** Specifies which city patterns should be considered by this control. */
  cities: string[];
  /** Specifies which countries should be considered by this control. */
  countries: string[];
  /** Determines if the provided criterias should cause authorizations to be declined or if they are pre-condtions for the authorizations to be considered for approval at all. */
  kind: CardsAuthorizationSelectorKind;
}

export enum CardsAuthorizationSelectorChannel {
  ATM_WITHDRAWAL = "ATM_WITHDRAWAL",
  CASHBACK_WITHDRAWAL = "CASHBACK_WITHDRAWAL",
  ECOMMERCE = "ECOMMERCE",
  OTHER = "OTHER",
}

export enum CardsAuthorizationSelectorKind {
  WHITELIST = "WHITELIST",
  BLACKLIST = "BLACKLIST",
}

export enum CardsAuthorizationStatus {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
  EXPIRED = "EXPIRED",
  CLOSED = "CLOSED",
  REVERSED = "REVERSED",
}

export interface CardsCard {
  /** @format uuid */
  id: string;
  /** @format uuid */
  accountId: string;
  /** @format uuid */
  tenantId: string;
  /** @format uuid */
  cardHolderId: string;
  /** @format uuid */
  definitionId: string;
  last4: string;
  kind: CardsCardType;
  /** ISO 4217 alphabetic currency code. */
  currency: Currency;
  expiration: CardsExpiration;
  datetimes: CardsCardDateTimes;
  externalReference?: string | null;
  status: CardsCardStatus;
  replacement?: CardsCardReplacement | null;
}

export interface CardsCardDateTimes {
  /** @format date-time */
  expires: string;
  /** time of the entity creation */
  created: string;
  /** @format date-time */
  lastUpdated: string;
}

export interface CardsCardDetails {
  /** @format uuid */
  id: string;
  /** @format uuid */
  accountId: string;
  /** @format uuid */
  tenantId: string;
  /** @format uuid */
  cardHolderId: string;
  /** @format uuid */
  definitionId: string;
  last4: string;
  kind: CardsCardType;
  /** ISO 4217 alphabetic currency code. */
  currency: Currency;
  expiration: CardsExpiration;
  datetimes: CardsCardDateTimes;
  externalReference?: string | null;
  status: CardsCardStatus;
  personalization: CardsPersonalization;
  fulfillment: CardsFulfillment;
  replacement?: CardsCardReplacement | null;
}

export interface CardsCardHolderDatetimes {
  /** Creation time */
  created: string;
  /** Last update time */
  lastUpdated: string;
}

export interface CardsCardHolderListEntry {
  /** The tenant to which this card holder belongs to */
  tenantId: string;
  /** Identity ids for the card holder */
  identityId: string;
  /** Name written on the card */
  nameOnCard?: string | null;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
  /** Label for the card holder */
  label?: string | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Card holder's phone number */
  phoneNumber?: PhoneNumber | null;
  /** The id of this entity */
  id: string;
  /** Related timestamps */
  datetimes: CardsCardHolderDatetimes;
}

export enum CardsCardHoldersOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export enum CardsCardNetwork {
  CARD_NETWORK_MASTERCARD = "CARD_NETWORK_MASTERCARD",
  CARD_NETWORK_VISA = "CARD_NETWORK_VISA",
}

export enum CardsCardOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface CardsCardPersonalization {
  line2?: string | null;
  line3?: string | null;
  qrCodeContent?: string | null;
}

export interface CardsCardReplacement {
  /** Reason why this card is getting replaced */
  reason: CardsCardReplacementReason;
  /** The ID of the card that was replaced */
  replacesCardId: string;
  /** The ID of the initial card in the replacement chain */
  initialCardId: string;
}

export interface CardsCardReplacementPersonalization {
  qrCodeContent?: string | null;
}

export enum CardsCardReplacementReason {
  LOST = "LOST",
  STOLEN = "STOLEN",
}

export interface CardsCardReplacementRequest {
  /** the card to be replaced */
  replacesCardId: string;
  /** Reason why this card is getting replaced */
  reason: CardsCardReplacementReason;
  personalization: CardsCardReplacementPersonalization;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export enum CardsCardStatus {
  ACTIVATED = "ACTIVATED",
  UNACTIVATED = "UNACTIVATED",
  LOCKED = "LOCKED",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  TERMINATED_OBSOLETE = "TERMINATED_OBSOLETE",
}

export enum CardsCardType {
  VIRTUAL = "VIRTUAL",
  PHYSICAL = "PHYSICAL",
}

export enum CardsChangeCardStatus {
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED_OBSOLETE = "TERMINATED_OBSOLETE",
}

export interface CardsChangeCardStatusRequest {
  status: CardsChangeCardStatus;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
}

export interface CardsChangeCardStatusResponse {
  /** @format uuid */
  id: string;
  status: CardsChangeCardStatus;
}

export interface CardsCreateAppleProvisioningRequest {
  /** Unique identifier for the card that the provisioning request is for */
  cardId: string;
  wallet: CardsAppleWallet;
  app: CardsAppleWalletApp;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsCreateAppleWebPushProvisioningRequest {
  /** Unique identifier for the card that the provisioning request is for */
  cardId: string;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsCreateAuthorizationControl {
  /** Label that describes the usage of the authorization control. */
  label?: string | null;
  /** The tenant the control should belong to. */
  tenantId: string;
  notes?: JsonNotes | null;
  /** The target entity of the authorization control. */
  target: CardsAuthorizationControlTarget;
  /** At least one value of each field has to match an incomming authorization for that authorzation to be included in the control. */
  authorization: CardsAuthorizationSelector;
  /** Date times for the authorization control. */
  datetimes: CardsCreateAuthorizationControlDateTimes;
}

export interface CardsCreateAuthorizationControlDateTimes {
  /** The authorization control will be active from this point in time. Not providing a start time means `effective_from` will default to the current date and time, making the control imediately effective. */
  effectiveFrom?: string | null;
  /** The authorization control will no longer applied after this point in time. */
  effectiveUntil?: string | null;
}

export interface CardsCreateCard {
  /** account id of this entity */
  accountId: string;
  /** tenant id of this entity */
  tenantId: string;
  /** card holder id of this entity */
  cardHolderId: string;
  /** definition id of this entity */
  definitionId: string;
  personalization?: CardsCardPersonalization | null;
  /** external reference for this entity */
  externalReference?: string | null;
}

export interface CardsCreateCardHolder {
  /** The tenant to which this card holder belongs to */
  tenantId: string;
  /** Identity ids for the card holder */
  identityId: string;
  /** Name written on the card */
  nameOnCard?: string | null;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
  /** Label for the card holder */
  label?: string | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Card holder's phone number */
  phoneNumber?: PhoneNumber | null;
}

export interface CardsCreateDockVaultTicketRequest {
  operations: CardsVaultOperations;
}

export interface CardsCreateDockVaultTicketResponse {
  /** @format uuid */
  id: string;
  datetimes: CardsVaultTicketDateTimes;
  ticket: string;
  accessToken: CardsAccessToken;
}

export interface CardsCreateGoogleProvisioningRequest {
  /** Unique identifier for the card that the provisioning request is for */
  cardId: string;
  wallet: CardsGoogleWallet;
  app: CardsGoogleWalletApp;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsCreateOrUpdateVelocityControlDateTimes {
  /**
   * Optional start date of the velocity control.
   * The
   *     control will not be taken into account before this date. If no date is
   *     provided, the start date will be equal to the date and time of creation.
   *     Please note that with the window kind *day* or *month* transactions that happened
   *     before the *effective_from* date but inside the window range are taken into
   *     account for the calculation for the velocity control.
   */
  effectiveFrom?: string | null;
  /** Optional end date. If a date is set, the control will no longer be taken into account after the provided date and time. */
  effectiveUntil?: string | null;
}

export interface CardsCreateVelocityControl {
  /** Optional label to describe the new velocity control */
  label?: string | null;
  /** @format uuid */
  tenantId: string;
  /** The target of this velocity control. */
  target: CardsVelocityControlTarget;
  /** The scope according to which the velocity control is being applied. Currently there is only one kind of scope available. */
  scope: CardsVelocityControlScope;
  /** The velocity constraints that should be applied as part of this control. */
  velocity: CardsVelocityControlVelocity;
  /** The time window in which the velocity control will be applied. */
  window: CardsVelocityControlWindow;
  /** Selector for the authorizations this control will be applied to. */
  selector?: CardsAuthorizationKindlessSelector | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  config?: CardsVelocityControlProcessingConfig | null;
  /** Various related timestamps */
  datetimes?: CardsCreateOrUpdateVelocityControlDateTimes | null;
}

export interface CardsCreatedAuthorizationControl {
  /** Id of the authorization control */
  id: string;
  /** Label that describes the usage of the authorization control. */
  label: string;
  /** The tenant the control should belong to. */
  tenantId: string;
  notes?: JsonNotes | null;
  /** The target entity of the authorization control. */
  target: CardsAuthorizationControlTarget;
  /** At least one value of each field has to match an incomming authorization for that authorzation to be included in the control. */
  authorization: CardsAuthorizationSelector;
  /** Date times for the authorization control. */
  datetimes: CardsAuthorizationControlDateTimes;
}

export interface CardsCreatedCard {
  /** @format uuid */
  id: string;
  /** @format uuid */
  accountId: string;
  /** @format uuid */
  tenantId: string;
  /** @format uuid */
  cardHolderId: string;
  /** @format uuid */
  definitionId: string;
  last4: string;
  kind: CardsCardType;
  /** ISO 4217 alphabetic currency code. */
  currency: Currency;
  expiration: CardsExpiration;
  datetimes: CardsCardDateTimes;
  externalReference?: string | null;
  status: CardsCreatedCardStatus;
  personalization: CardsPersonalization;
  fulfillment: CardsFulfillment;
}

export interface CardsCreatedCardHolder {
  /** The tenant to which this card holder belongs to */
  tenantId: string;
  /** Identity ids for the card holder */
  identityId: string;
  /** Name written on the card */
  nameOnCard?: string | null;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
  /** Label for the card holder */
  label?: string | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Card holder's phone number */
  phoneNumber?: PhoneNumber | null;
  /** The id of this entity */
  id: string;
  /** Related timestamps */
  datetimes: CardsCardHolderDatetimes;
}

export enum CardsCreatedCardStatus {
  ACTIVATED = "ACTIVATED",
  UNACTIVATED = "UNACTIVATED",
}

export interface CardsCreatedReplacementCard {
  /** @format uuid */
  id: string;
  /** @format uuid */
  accountId: string;
  /** @format uuid */
  tenantId: string;
  /** @format uuid */
  cardHolderId: string;
  /** @format uuid */
  definitionId: string;
  last4: string;
  kind: CardsCardType;
  /** ISO 4217 alphabetic currency code. */
  currency: Currency;
  expiration: CardsExpiration;
  datetimes: CardsCardDateTimes;
  externalReference?: string | null;
  status: CardsCreatedCardStatus;
  personalization: CardsPersonalization;
  fulfillment: CardsFulfillment;
  replacement: CardsCardReplacement;
}

export interface CardsCreatedVelocityControl {
  /** id of the velocity control */
  id: string;
  /** Label to describe the new velocity control */
  label: string;
  /** The tenant to which this velocity control will belong */
  tenantId: string;
  /** The target of this velocity control. */
  target: CardsVelocityControlTarget;
  /** The scope according to which the velocity control is being applied. Currently there is only one kind of scope available. */
  scope: CardsVelocityControlScope;
  /** The velocity constraints that should be applied as part of this control. */
  velocity: CardsVelocityControlVelocity;
  /** The time window in which the velocity control will be applied. */
  window: CardsVelocityControlWindow;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Configuration parameters for the processing of velocity controls during authorizations. */
  config: CardsVelocityControlProcessingConfig;
  datetimes: CardsVelocityControlDateTimes;
}

export interface CardsExpiration {
  month: string;
  year: string;
}

export interface CardsFulfillment {
  status: CardsFulfillmentStatus;
}

export enum CardsFulfillmentStatus {
  ISSUED = "ISSUED",
  ORDERED = "ORDERED",
}

export interface CardsGoogleProvisioningRequest {
  /** Unique identifier for this provisioning request */
  id: string;
  /** Unique identifier for the card that the provisioning request is for */
  cardId: string;
  wallet: CardsGoogleWallet;
  app: CardsGoogleWalletApp;
  /** When the request was created */
  datetimes: CardsWalletProvisioningRequestDateTimes;
  /** Structure with data required for the tokenization request */
  receipt: CardsGoogleWalletReceipt;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsGoogleWallet {
  /** ID of the device the provisioning request is for */
  deviceId: string;
  /** Type of the device the provisioning request is for */
  deviceType: CardsWalletDeviceTypeProvisioning;
  /**
   * @minLength 1
   * @maxLength 50
   */
  accountId: string;
}

export interface CardsGoogleWalletApp {
  /** Version of the application requesting the provisioning request */
  version: string;
  /** Version of the application requesting the provisioning request */
  name: string;
}

export interface CardsGoogleWalletReceipt {
  /** Encrypted data that needs to be provided to Google Wallet */
  opaquePaymentCard: string;
  /** The service provider for the token */
  tokenServiceProvider: CardsTokenServiceProvider;
  /** The card network using of the token */
  network: CardsCardNetwork;
}

export interface CardsGoogleWebPushProvisioningRequest {
  /** Unique identifier for the card that should be provisioned */
  cardId: string;
  wallet: CardsGoogleWebPushProvisioningWallet;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
}

export interface CardsGoogleWebPushProvisioningResponse {
  /** Unique identifier for the web push provisioning attempt */
  id: string;
  datetimes: CardsWalletProvisioningRequestDateTimes;
}

export interface CardsGoogleWebPushProvisioningWallet {
  /** The wallet ID */
  id: string;
  /** ID of the device the card should be provisioned on */
  deviceId: string;
  /** Google-assigned string that uniquely identifies both the integrator that is initiating the session and the issuer of the card. */
  integratorId: string;
  /** String provided by Google Wallet that identifies the client session. */
  sessionId: string;
  /** String provided by Google Wallet that identifies the backend session. */
  serverSessionId: string;
}

export interface CardsMerchant {
  /** Merchant name */
  name?: string | null;
  /** Merchant category code */
  mcc?: string | null;
  /** ID provided by the merchant */
  mid?: string | null;
  /** Postal code provided by the merchant */
  postalCode?: string | null;
  /** City provided by the merchant */
  city?: string | null;
  /** Country code ([ISO 3166 alpha-3](https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes) e.g. DEU, BEL, NLD, LUX, ...) provided by the merchant */
  country?: string | null;
}

export enum CardsMethod {
  POS = "POS",
  ECOMMERCE = "ECOMMERCE",
  ATM = "ATM",
  MAIL = "MAIL",
  PHONE = "PHONE",
  MOTO = "MOTO",
  OTHER = "OTHER",
  UNAVAILABLE = "UNAVAILABLE",
}

export interface CardsPersonalization {
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  qrCodeContent?: string | null;
}

/** if-match precondition on token status */
export enum CardsPreconditionTokenStatus {
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
}

export enum CardsRequestedTokenStatus {
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED_OBSOLETE = "TERMINATED_OBSOLETE",
}

export interface CardsSource {
  method: CardsMethod;
}

export enum CardsTokenServiceProvider {
  TOKEN_PROVIDER_MASTERCARD = "TOKEN_PROVIDER_MASTERCARD",
  TOKEN_PROVIDER_VISA = "TOKEN_PROVIDER_VISA",
}

export interface CardsTokenTransitionRequest {
  /** New status to be assigned */
  status: CardsRequestedTokenStatus;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
}

export interface CardsTokenTransitionResponse {
  /** Unique identifier for the transition */
  id: string;
  /** New status */
  status: CardsWalletTokenStatus;
}

export interface CardsTransaction {
  amounts: CardsTransactionAmounts;
  /** empty for refunds and forced clearings */
  authorizationId?: string | null;
  /** @format uuid */
  cardId: string;
  currencyConversion: CardsTransactionCurrencyConversion;
  datetimes: CardsTransactionDateTimes;
  /** the id this entity */
  id: string;
  kind: CardsTransactionKind;
  merchant: CardsTransactionMerchant;
  /** Card Transaction Source */
  source: CardsTransactionSource;
  status: CardsTransactionStatus;
  wallet?: CardsWallet | null;
}

export interface CardsTransactionAmounts {
  /** Represents a monetary value of a specific currency. */
  total: Monetary;
  /** Represents a monetary value of a specific currency. */
  totalMerchant: Monetary;
}

export interface CardsTransactionCurrencyConversion {
  /** @format decimal */
  conversionRate: string;
}

export interface CardsTransactionDateTimes {
  /** RFC 3339 (ISO 8601) */
  completed: string;
  /** RFC 3339 (ISO 8601) */
  initiated: string;
}

export enum CardsTransactionKind {
  CAPTURE = "CAPTURE",
  REFUND = "REFUND",
  CHARGEBACK = "CHARGEBACK",
  REPRESENTMENT = "REPRESENTMENT",
}

export interface CardsTransactionMerchant {
  /** Merchant name */
  name?: string | null;
  /** Merchant category code */
  mcc?: string | null;
  /** ID provided by the merchant */
  mid?: string | null;
  /** Postal code provided by the merchant */
  postalCode?: string | null;
  /** City provided by the merchant */
  city?: string | null;
  /** Country code ([ISO 3166 alpha-3](https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes) e.g. DEU, BEL, NLD, LUX, ...) provided by the merchant */
  country?: string | null;
}

export enum CardsTransactionMethod {
  POS = "POS",
  ECOMMERCE = "ECOMMERCE",
  ATM = "ATM",
  MAIL = "MAIL",
  PHONE = "PHONE",
  MOTO = "MOTO",
  OTHER = "OTHER",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum CardsTransactionOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface CardsTransactionSource {
  /** Card Transaction Channel */
  method: CardsTransactionMethod;
}

export enum CardsTransactionStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export interface CardsUpdateCardHolder {
  phoneNumber?: PhoneNumber | null;
  nameOnCard?: string | null;
  label?: string | null;
  notes?: JsonNotes | null;
  identityId?: string | null;
}

export interface CardsUpdateVelocityControl {
  /** Optional label to describe the velocity control */
  label?: string | null;
  /** The velocity constraints that should be applied as part of this control. */
  velocity: CardsVelocityControlVelocity;
  /** The time window in which the velocity control will be applied. */
  window: CardsVelocityControlWindow;
  /** Various related timestamps */
  datetimes?: CardsCreateOrUpdateVelocityControlDateTimes | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Configuration parameters for the processing of velocity controls during authorizations. */
  config?: CardsVelocityControlProcessingConfig | null;
}

export interface CardsUpdatedCardHolder {
  /** The tenant to which this card holder belongs to */
  tenantId: string;
  /** Identity ids for the card holder */
  identityId: string;
  /** Name written on the card */
  nameOnCard?: string | null;
  /** Value that can be assigned to arbitrary string for external reference */
  externalReference?: string | null;
  /** Label for the card holder */
  label?: string | null;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Card holder's phone number */
  phoneNumber?: PhoneNumber | null;
  /** @format uuid */
  id: string;
  datetimes: CardsCardHolderDatetimes;
}

export interface CardsVaultAccessTokenDateTimes {
  /** time of the expiration */
  expires: string;
}

export enum CardsVaultOperationRead {
  GET = "GET",
}

export enum CardsVaultOperationReadWrite {
  GET = "GET",
  SET = "SET",
}

export interface CardsVaultOperations {
  cvv?: CardsVaultOperationRead | null;
  expiration?: CardsVaultOperationRead | null;
  pan?: CardsVaultOperationRead | null;
  pin?: CardsVaultOperationReadWrite | null;
}

export interface CardsVaultTicketDateTimes {
  /** time of the expiration */
  expires: string;
}

export interface CardsVelocityControlDateTimes {
  /** The date and time at which a velocity control was created. */
  created: string;
  /** The date and time at which a velocity control was last updated. */
  lastUpdated: string;
  /** Start date of the velocity control, the control will not be considered before this date. */
  effectiveFrom: string;
  /** Optional end date. If a date is set, the control will no longer be taken into account after the provided date and time. */
  effectiveUntil?: string | null;
}

export interface CardsVelocityControlListEntry {
  /** the id of this entity */
  id: string;
  /** Label to describe the velocity control */
  label?: string | null;
  /** The tenant to which this velocity control will belong */
  tenantId: string;
  /** The target of this velocity control. */
  target: CardsVelocityControlTarget;
  /** The scope according to which the velocity control is being applied. Currently there is only one kind of scope available. */
  scope: CardsVelocityControlScope;
  /** The velocity constraints that should be applied as part of this control. */
  velocity: CardsVelocityControlVelocity;
  /** The time window in which the velocity control will be applied. */
  window: CardsVelocityControlWindow;
  /** Free form JSON object with custom information */
  notes?: JsonNotes | null;
  /** Configuration parameters for the processing of velocity controls during authorizations. */
  config: CardsVelocityControlProcessingConfig;
  /** Various related timestamps */
  datetimes: CardsVelocityControlDateTimes;
}

/** The possible kinds of entities a velocity control can target. */
export interface CardsVelocityControlProcessingConfig {
  /** Determines the manner in which Refund transactions are handled when processing velocity controls. */
  refund: CardsVelocityControlRefundHandling;
}

/** The possible kinds of entities a velocity control can target. */
export enum CardsVelocityControlRefundHandling {
  Ignore = "IGNORE",
  UpToLimit = "UP_TO_LIMIT",
}

export enum CardsVelocityControlScope {
  INDIVIDUAL = "INDIVIDUAL",
}

export interface CardsVelocityControlTarget {
  /** The kind of velocity control target. The target id will be interpreted according to this value. */
  kind: CardsVelocityControlTargetKind;
  /** An id of an entity that is of the specified kind. */
  id: string;
}

/** The possible kinds of entities a velocity control can target. */
export enum CardsVelocityControlTargetKind {
  CARD = "CARD",
  CARD_PRODUCT_DEFINITION = "CARD_PRODUCT_DEFINITION",
}

export interface CardsVelocityControlVelocity {
  /** . The amount of money that can be spent in a single time window of the control. */
  spendingLimit: Monetary;
}

export interface CardsVelocityControlWindow {
  /** The kind of time window that should be applied to a velocity control. */
  kind: CardsVelocityControlWindowKind;
  /** The number of natural months a velocity control should take into account. This is only valid for window kind MONTHS and has to be null for all other kinds. */
  months?: number | null;
  /** The number of days a velocity control should take into account. This is only valid for window kind DAYS and has to be null for all other kinds. */
  days?: number | null;
}

/**
 * The kind of time window according to which a velocity control will be applied.
 * - PER_TRANSACTION: Control will be applied to a single transaction.
 * - LIFETIME: Control will be applied to all transactions over the entire lifetime of the target. The entire transaction history of the target will be taken into account when validating the velocity constraints.
 * - MONTHS: The control will be applied to all transactions in the time frame of the specified number of natural months. After the specified time period the control resets and restarts for a new interval.
 * - DAYS: Behaves simlar to MONTHS but based on a number of days.
 */
export enum CardsVelocityControlWindowKind {
  PER_TRANSACTION = "PER_TRANSACTION",
  MONTHS = "MONTHS",
  DAYS = "DAYS",
  LIFETIME = "LIFETIME",
}

export enum CardsVelocityControlsOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface CardsWallet {
  /** @format uuid */
  tokenId: string;
}

export interface CardsWalletDevice {
  /** The device type this token was provisioned for */
  kind: CardsWalletDeviceType;
  /** name of the device */
  name?: string | null;
  /** identifier of the device */
  id?: string | null;
}

/** The device type this token was provisioned for */
export enum CardsWalletDeviceType {
  MOBILE_PHONE = "MOBILE_PHONE",
  TABLET = "TABLET",
  WATCH = "WATCH",
  APPLIANCE = "APPLIANCE",
  VEHICLE = "VEHICLE",
  GAMING_DEVICE = "GAMING_DEVICE",
  UNKNOWN = "UNKNOWN",
  LAPTOP = "LAPTOP",
}

/** The possible devices to provisions wallet tokens for. */
export enum CardsWalletDeviceTypeProvisioning {
  MOBILE_PHONE = "MOBILE_PHONE",
  TABLET = "TABLET",
  WATCH = "WATCH",
}

export interface CardsWalletProvisioningRequestDateTimes {
  /** The date and time at which the provisioning request was created */
  created: string;
  /** The date and time at which the provisioning request was created */
  lastUpdated: string;
}

export interface CardsWalletTokenDateTimes {
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  lastUpdatedAt: string;
}

export interface CardsWalletTokenFulfillment {
  /** Token fulfillment decision */
  decision: CardsWalletTokenFulfillmentDecision;
}

export enum CardsWalletTokenFulfillmentDecision {
  DECISION_RED = "DECISION_RED",
  DECISION_YELLOW = "DECISION_YELLOW",
  DECISION_GREEN = "DECISION_GREEN",
  REJECTED_ISSUER = "REJECTED_ISSUER",
}

export interface CardsWalletTokenListEntry {
  /** Unique identifier for this wallet token */
  id: string;
  /** Identifier for the card of this wallet token */
  cardId: string;
  /** Status of this wallet token */
  status: CardsWalletTokenStatus;
  /** Fulfillment information for this wallet token */
  fulfillment: CardsWalletTokenFulfillment;
  provider: CardsWalletTokenProvider;
  datetimes: CardsWalletTokenDateTimes;
  requestor: CardsWalletTokenRequestor;
  device: CardsWalletDevice;
}

export enum CardsWalletTokenOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface CardsWalletTokenProvider {
  /** unique identifier of the wallet token within the card network */
  reference: string;
}

export interface CardsWalletTokenRequestor {
  /** name of the entity requesting the wallet token */
  name: string;
}

export enum CardsWalletTokenStatus {
  PENDING = "PENDING",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  TERMINATED_OBSOLETE = "TERMINATED_OBSOLETE",
  TERMINATED_DECLINED = "TERMINATED_DECLINED",
}

export interface CardsAuthorizationControlTargetCard {
  /** Id of the targeted card. */
  id: string;
}

export interface CardsAuthorizationControlTargetCardProductDefinition {
  /** Id of the targeted card product definition. */
  id: string;
}

export type CardsAuthorizationEventOutcomeNoDetails = object;

export interface CardsAuthorizationEventOutcomeVelocityControlDeclined {
  /** ID of the VelocityControl that caused the authorization decline */
  velocityControlId: string;
}

export type ErrorApiErrorReason =
  | ({
      type: "MISSING_FIELD";
    } & ErrorMissingField)
  | ({
      type: "UNEXPECTED_FIELD";
    } & ErrorUnexpectedField)
  | ({
      type: "UNKNOWN_VARIANT";
    } & ErrorUnknownVariant)
  | ({
      type: "INVALID_TYPE";
    } & ErrorInvalidType)
  | ({
      type: "INVALID_VALUE";
    } & ErrorInvalidValue)
  | ({
      type: "MISSING_QUERY_ARGUMENT";
    } & ErrorMissingQueryArgument)
  | ({
      type: "INVALID_QUERY_ARGUMENT";
    } & ErrorInvalidQueryArgument)
  | ({
      type: "MISSING_HEADER";
    } & ErrorMissingHeader)
  | ({
      type: "MISSING_AUTH_HEADER";
    } & ErrorMissingAuthHeader)
  | ({
      type: "INVALID_HEADER";
    } & ErrorInvalidHeader)
  | ({
      type: "INVALID_AUTH_HEADER";
    } & ErrorInvalidAuthHeader)
  | ({
      type: "MISSING_PATH_ARGUMENT";
    } & ErrorMissingPathArgument)
  | ({
      type: "INVALID_PATH_ARGUMENT";
    } & ErrorInvalidPathArgument)
  | ({
      type: "NOT_FOUND";
    } & ErrorNotFound)
  | ({
      type: "INTERNAL";
    } & ErrorInternal)
  | ({
      type: "UNAUTHORIZED";
    } & ErrorUnauthorized)
  | ({
      type: "FAILED_PRECONDITION";
    } & ErrorFailedPrecondition)
  | ({
      type: "BAD_GATEWAY";
    } & ErrorBadGateway)
  | ({
      type: "SERVICE_UNAVAILABLE";
    } & ErrorServiceUnavailable)
  | ({
      type: "UNPROCESSABLE_ENTITY";
    } & ErrorUnprocessableEntity)
  | ({
      type: "FORBIDDEN";
    } & ErrorForbidden)
  | ({
      type: "CONFLICT";
    } & ErrorConflict)
  | ({
      type: "NOT_IMPLEMENTED";
    } & ErrorNotImplemented)
  | ({
      type: "GENERIC_BAD_REQUEST";
    } & ErrorGenericBadRequest)
  | ({
      type: "MISDIRECTED_REQUEST";
    } & ErrorMisdirectedRequest);

export type ErrorBadGateway = object;

export type ErrorConflict = object;

export type ErrorFailedPrecondition = object;

export type ErrorForbidden = object;

export type ErrorGenericBadRequest = object;

export type ErrorInternal = object;

export type ErrorInvalidAuthHeader = object;

export type ErrorInvalidHeader = object;

export interface ErrorInvalidPathArgument {
  key: string;
}

export type ErrorInvalidQueryArgument = object;

export interface ErrorInvalidType {
  expected: string;
  value: string;
}

export interface ErrorInvalidValue {
  valueType: string;
  value: string;
}

export type ErrorMisdirectedRequest = object;

export type ErrorMissingAuthHeader = object;

export interface ErrorMissingField {
  objTy: string;
  field: string;
}

export type ErrorMissingHeader = object;

export type ErrorMissingPathArgument = object;

export type ErrorMissingQueryArgument = object;

export interface ErrorNotFound {
  entity: string;
  value?: string | null;
}

export type ErrorNotImplemented = object;

export type ErrorServiceUnavailable = object;

export type ErrorUnauthorized = object;

export interface ErrorUnexpectedField {
  fieldType: string;
  field: string;
}

export interface ErrorUnknownVariant {
  variantType: string;
  variant: string;
}

export type ErrorUnprocessableEntity = object;

export interface TenantsCreateTenant {
  /** A human readable label for the tenant. */
  label: string;
  /** The tenant under which the new tenant will be created. */
  parentId: string;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface TenantsDateTimes {
  /** When the tenant was created. */
  created: string;
  /** When the tenant was last modified. */
  updated: string;
}

export interface TenantsTenant {
  /** The id of the entity. */
  id: string;
  /** The id of the parent tenant. */
  parentId?: string | null;
  /** A human readable label of the tenant. */
  label: string;
  /** A collection of datetimes associated with various events throughout the tenant's lifetime. */
  datetimes: TenantsDateTimes;
  /** json notes */
  notes?: JsonNotes | null;
}

export enum TenantsTenantOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface TenantsUpdateTenantLabel {
  /** The new label for this tenant */
  label: string;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersAssignProviderToUser {
  /** id of an oauth provider */
  oauthProviderId: string;
  /** User id at oauth provider for oauth assignment */
  uid?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersAuthMethodDatetimes {
  /** time of the auth method creation */
  created: string;
  /** time of the auth method expiration */
  expires: string;
}

export enum UsersAuthMethodStatus {
  UNACTIVATED = "UNACTIVATED",
  ENABLED = "ENABLED",
  SINGLE_USE = "SINGLE_USE",
  DISABLED = "DISABLED",
  TERMINATED = "TERMINATED",
}

export enum UsersCapabilityAction {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum UsersCapabilityObject {
  ACCOUNT = "ACCOUNT",
  CARD = "CARD",
  CARD_STATUS = "CARD_STATUS",
  A2AHOLDER = "A2A_HOLDER",
  A2ATRANSFER = "A2A_TRANSFER",
  A2ATRANSFERSTATUS = "A2A_TRANSFER_STATUS",
  A2APAYMENT = "A2A_PAYMENT",
}

export interface UsersCreateCryptoKey {
  /** PEM encoded public key (supported algorithms are Ed25519, P-521 and RSASSA) */
  cryptoKey: string;
  /** an optional label for this entity */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersCreateDeviceToken {
  /** an optional label for this entity */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersCreateOAuthProvider {
  /** oauth url the user needs visit for authentication */
  authUrl: string;
  /** oauth url to exchange code against access token */
  tokenUrl: string;
  /** a public identifier for apps */
  clientId: string;
  /** tenant id of an oauth provider */
  tenantId: string;
  /** a human readable label for the oauth provider */
  label: string;
}

export interface UsersCreateResetToken {
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** policies to be applied when creating the reset token */
  policies: UsersResetTokenPolicy[];
  /** defines how long the reset token can be used (in minutes, defaults to 24 hours) */
  resetTokenLifetime?: number | null;
  /** defines how long the access token can be used when the reset token is redeemed (in minutes, defaults to 5 minutes) */
  accessTokenLifetime?: number | null;
  /** defines how long the access token can be refreshed when the reset token is redeemed (in minutes, defaults to 5 minutes) */
  refreshTokenLifetime?: number | null;
}

export interface UsersCreateUser {
  /** the tenant id at which this user should be created */
  tenantId: string;
  /** the identity id for this user */
  identityId: string;
  /** a label for this user */
  label: string;
  /** email of authenticated user, note that this field will go away, this should be stored in the identity */
  email?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersCryptoKey {
  /** the id this entity */
  id: string;
  /** the user id this entity belongs to */
  userId: string;
  /** timestamps for this entity */
  datetimes: UsersAuthMethodDatetimes;
  /** status of this entity */
  status: UsersAuthMethodStatus;
  /** PEM encoded public key */
  cryptoKey: string;
  /** label of this entity */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export enum UsersCryptoKeyOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface UsersCurrentActor {
  /** the id this entity */
  id: string;
  /** tenant of this entity */
  tenantId: string;
  /** label of this entity */
  label?: string | null;
}

export interface UsersCurrentIdentity {
  /** the id this entity */
  id: string;
  /** email address */
  emailAddress?: string | null;
  /** names of this identiy */
  name?: string | null;
}

export interface UsersDatetimes {
  /** time of the entity creation */
  created: string;
}

export interface UsersDeviceToken {
  /** the id this entity */
  id: string;
  /** the user id this entity belongs to */
  userId: string;
  /** timestamps for this entity */
  datetimes: UsersDeviceTokenDatetimes;
  /** status of this entity */
  status: UsersTokenStatus;
  /** label of this entity */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersDeviceTokenDatetimes {
  /** time of the device token creation */
  created: string;
  /** time of the device token expiration */
  expires: string;
}

export enum UsersDeviceTokenOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface UsersNewDeviceToken {
  /** the id this entity */
  id: string;
  /** the user id this entity belongs to */
  userId: string;
  /** timestamps for this entity */
  datetimes: UsersDeviceTokenDatetimes;
  /** status of this entity */
  status: UsersTokenStatus;
  /** label of this entity */
  label?: string | null;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** the device token required for authentication calls */
  deviceToken: string;
}

export interface UsersOAuthAssignment {
  /** id of the assignment */
  id: string;
  /** id of the assigned user */
  userId: string;
  /** id of the oauth provider */
  oauthProviderId: string;
  /** timestamps for this entity */
  datetimes: UsersOAuthAssignmentDatetimes;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
  /** status of the assignment */
  status: UsersAuthMethodStatus;
  /** User id at oauth provider for oauth assignment */
  uid?: string | null;
}

export interface UsersOAuthAssignmentDatetimes {
  /** time of the assignment creation */
  created: string;
  /** time of the assignment updating */
  updated: string;
}

export enum UsersOAuthAssignmentOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface UsersOAuthProvider {
  /** id of the oauth provider */
  id: string;
  /** a human readable label for the oauth provider */
  label: string;
  /** oauth url the user needs visit for authentication */
  authUrl: string;
  /** oauth url to exchange code against access token */
  tokenUrl: string;
  /** a public identifier for apps */
  clientId: string;
  /** status of OAuth provider */
  status: UsersAuthMethodStatus;
  /** timestamps for this entity */
  datetimes: UsersOAuthProviderDatetimes;
  /** tenant id of an oauth provider */
  tenantId: string;
}

export interface UsersOAuthProviderDatetimes {
  /** time of the entity creation */
  created: string;
}

export enum UsersOAuthProviderOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface UsersResetToken {
  /** reset token */
  token: string;
}

export enum UsersResetTokenPolicy {
  LOCK_AUTH_METHODS = "LOCK_AUTH_METHODS",
  REVOKE_SESSIONS = "REVOKE_SESSIONS",
}

export enum UsersStatus {
  UNACTIVATED = "UNACTIVATED",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED = "TERMINATED",
}

export enum UsersTokenStatus {
  UNACTIVATED = "UNACTIVATED",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  TERMINATED = "TERMINATED",
}

export interface UsersUser {
  /** the id this entity */
  id: string;
  /** identity id of user */
  identityId: string;
  /** label of user */
  label: string;
  /** timestamps for this entity */
  datetimes: UsersDatetimes;
  /** tenant id of user */
  tenantId: string;
  /** primary actor id of user */
  primaryActorId: string;
  /** status of user */
  status: UsersStatus;
  /** json notes (150 characters) */
  notes?: JsonNotes | null;
}

export interface UsersUserCapabilities {
  capabilities: UsersUserCapability[];
}

export interface UsersUserCapability {
  /** an entity that has actions assigned */
  object: UsersCapabilityObject;
  /** a list of allowed actions for this entity */
  actions: UsersCapabilityAction[];
}

export enum UsersUserOrderBy {
  NaturalOrderAsc = "+id",
  NaturalOrderDesc = "-id",
}

export interface UsersWhoAmI {
  /** authenticated user */
  user: UsersUser;
  /** authenticated actor id */
  currentActorId: string;
  /** currently used actor */
  currentActor: UsersCurrentActor;
  currentIdentity: UsersCurrentIdentity;
}

export interface ListInstrumentsParams {
  /** filter for a2a transfer holder id, e.g.: 00000000-0000-0000-0000-000000000000 */
  a2a_holder_id?: string[];
  /** filter for account id, e.g.: 00000000-0000-0000-0000-000000000000 */
  account_id?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** filter for definition id, e.g.: 00000000-0000-0000-0000-000000000000 */
  definition_id?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for a2a transfer holder id, e.g.: 00000000-0000-0000-0000-000000000000 */
  holder_id?: string[];
  /** filter for a2a instrument id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** status of account */
  status?: A2ATransfersInstrumentStatus[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: A2ATransfersInstrumentOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListInstrumentsData = A2ATransfersInstrument[];

export type CreateInstrumentData = A2ATransfersInstrument;

export interface ListHoldersParams {
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for a2a holder id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for identity id, e.g.: 00000000-0000-0000-0000-000000000000 */
  identity_id?: string[];
  /** status of holder */
  status?: A2ATransfersHolderStatus[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: A2ATransfersHolderOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListHoldersData = A2ATransfersHolder[];

export type CreateHolderData = A2ATransfersHolder;

export interface ListPaymentsParams {
  /** filter for a2a payment amount, e.g.: 00000000-0000-0000-0000-000000000000 */
  amount?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for a2a payment id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for a2a payment recipient instrument id, e.g.: 00000000-0000-0000-0000-000000000000 */
  recipient_id?: string[];
  /** filter for a2a payment sender instrument id, e.g.: 00000000-0000-0000-0000-000000000000 */
  sender_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: A2ATransfersPaymentOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListPaymentsData = A2ATransfersPayment[];

export interface ListPaymentEventsParams {
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** filter for a2a payment event id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for a2a payment kind */
  kind?: A2ATransfersPaymentEventKindFilter[];
  /** filter for a2a payment id, e.g.: 00000000-0000-0000-0000-000000000000 */
  payment_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: A2ATransfersPaymentEventOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListPaymentEventsData = A2ATransfersPaymentEvent[];

export interface ListA2ATransactionsParams {
  /** filter for a2a transaction amount, e.g.: 00000000-0000-0000-0000-000000000000 */
  amount?: string[];
  /** filter for counterparty a2a account id, e.g.: 00000000-0000-0000-0000-000000000000 */
  "counterparty/account_id"?: string[];
  /** Fill in parameter description */
  "counterparty/instrument_id"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for a2a transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for a2a instrument id (DEPRECATED: use party/instrumnt_id instead), e.g.: 00000000-0000-0000-0000-000000000000 */
  instrument_id?: string[];
  /** filter for party a2a account id, e.g.: 00000000-0000-0000-0000-000000000000 */
  "party/account_id"?: string[];
  /** Fill in parameter description */
  "party/instrument_id"?: string[];
  /** filter for a2a payment id, e.g.: 00000000-0000-0000-0000-000000000000 */
  payment_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: A2ATransfersTransactionOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListA2ATransactionsData = A2ATransfersTransaction[];

export type CreatePaymentData = A2ATransfersPayment;

export type UpdateInstrumentStatusData = A2ATransfersNewInstrumentStatus;

export interface ListAccountsParams {
  /** filter for account holder id, e.g.: 00000000-0000-0000-0000-000000000000 */
  account_holder_id?: string[];
  /** filter for accounts available balance, e.g.: >10.00,<=20.00 */
  "balances/available"?: string[];
  /** filter for accounts hold balance, e.g.: >10.00,<=20.00 */
  "balances/hold"?: string[];
  /** filter for accounts total balance, e.g.: >10.00,<=20.00 */
  "balances/total"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/updated"?: string[];
  /** filter for definition id, e.g.: 00000000-0000-0000-0000-000000000000 */
  definition_id?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for account id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for account label */
  label?: string[];
  /** status of account */
  status?: AccountsAccountStatus[];
  /** filter for account tags */
  tags?: string[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: AccountsAccountOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListAccountsData = AccountsAccount[];

export type CreateAccountData = AccountsAccount;

export interface ListAccountHoldersParams {
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/updated"?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for identity id, e.g.: 00000000-0000-0000-0000-000000000000 */
  identity_id?: string[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: AccountsAccountHolderOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListAccountHoldersData = AccountsAccountHolder[];

export type CreateAccountHolderData = AccountsAccountHolder;

export interface ListTransactionsParams {
  /** filter for account id, e.g.: 00000000-0000-0000-0000-000000000000 */
  account_id?: string[];
  /** filter for amount */
  amount?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/initiated"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/value_dated"?: string[];
  /** filter for transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** [DEPRECATED] use 'source/transaction_id' instead */
  reference_transaction_id?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "source/method"?: AccountsPaymentMethod[];
  /** filter for source transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  "source/transaction_id"?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: AccountsTransactionOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListTransactionsData = AccountsTransaction[];

export type UpdateAccountStatusData = AccountsUpdateAccountStatusResponse;

export type UpdateAccountTagsData = AccountsAccountTagsHistoryEntry;

export interface ListActorsParams {
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** filter for actor id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for label */
  label?: string[];
  /** filter for role id, e.g.: 00000000-0000-0000-0000-000000000000 */
  role_id?: string[];
  /** status of actor */
  status?: ActorsStatus[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: ActorsActorOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListActorsData = ActorsActor[];

export type CreateActorData = ActorsActor;

export interface ListImpersonationsParams {
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: ActorsImpersonationsOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListImpersonationsData = ActorsImpersonation[];

export type UpdateActorLabelData = ActorsActorLabelUpdate;

export type UpdateActorRolesData = ActorsUpdateActorRolesResponse;

export interface GetCardsParams {
  /** filter for account id, e.g.: 00000000-0000-0000-0000-000000000000 */
  account_id?: string[];
  /** filter for card holder id, e.g.: 00000000-0000-0000-0000-000000000000 */
  card_holder_id?: string[];
  /** filter for card status, e.g.: ACTIVATED */
  card_status?: CardsCardStatus[];
  /** filter for card type, e.g.: PHYSICAL */
  card_type?: CardsCardType[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/expires"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/last_updated_at"?: string[];
  /** filter for definition id, e.g.: 00000000-0000-0000-0000-000000000000 */
  definition_id?: string[];
  /** filter for external reference */
  external_reference?: string[];
  /** filter for card id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for last4 (last 4 digits of card), e.g.: 1234 */
  last4?: string[];
  /** the initial card id, e.g.: 2fa95625-4521-4596-b3aa-96965476a4ab */
  "replacement/initial_card_id"?: string[];
  /** the initial card id, e.g.: 2fa95625-4521-4596-b3aa-96965476a4ab */
  "replacement/reason"?: CardsCardReplacementReason[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsCardOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type GetCardsData = CardsCard[];

export type CreateCardData = CardsCreatedCard;

export interface ListAuthorizationControlsParams {
  /** Filter authorization controls by an authorization channel. */
  "authorization/channel"?: CardsAuthorizationSelectorChannel[];
  /** Filter authorization controls by an authorization selector kind. */
  "authorization/kind"?: CardsAuthorizationSelectorKind[];
  /** Filter authorization controls by city. */
  city?: string[];
  /** Filter authorization controls by country code (ISO 3166 alpha 2). */
  country?: string[];
  /** Filter authorization controls by their effective_from date. */
  "datetimes/effective_from"?: string[];
  /** Filter for the authorization control id. */
  id?: string[];
  /** Filter authorization controls by postal code. */
  postal_code?: string[];
  /** Filter authorization controls by target id. */
  "target/id"?: string[];
  /** Filter authorization controls by target kind. */
  "target/kind"?: CardsAuthorizationControlTargetKind[];
  /** Filter authorization controls by tenant_id. */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsAuthorizationControlOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListAuthorizationControlsData = CardsCreatedAuthorizationControl[];

export type CreateAuthorizationControlData = CardsCreatedAuthorizationControl;

export interface ListCardAuthorizationsParams {
  /**
   *
   *     Filter for the approved hold amount. Let's assume an initial authorization request for 50 EUR is received.
   *     Then a new authorization is shown with PENDING as status and the approved amount of 0.00.
   *     After the authorization was approved the amount shows the requested value of -50.00 EUR (please note the signed value).
   *     In case that afterwards an authorization advice of 20 EUR is received, the approved amount of -30.00 EUR is shown.
   *
   *     You can search for authorizations with an hold amount of more than 50 EUR with a filter
   *     value of <=-50.00. Please note the signed value and that you need to url encode special characters like '<', '=', '/', '>', etc.
   *     This means the encoded value for '<=-50.00' is 'amounts%2Fapproved%2Famount=%3C%3D-50.00'.
   *
   */
  "amounts/approved/amount"?: string[];
  /** Filter for approved amount currency, e.g.: EUR, USD (note that you need to url encode special characters like '/', '>', ...) */
  "amounts/approved/currency"?: Currency[];
  /** filter for card id, e.g.: 00000000-0000-0000-0000-000000000000 */
  card_id?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/initiated"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/last_updated"?: string[];
  /** filter for transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** Filter for city (note that you need to url encode the '/') */
  "merchant/city"?: string[];
  /** Filter for country code ([ISO 3166 alpha-3](https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes) e.g. DEU, BEL, NLD, LUX, ..., and note that you need to url encode the '/') */
  "merchant/country"?: string[];
  /** Filter for merchant mcc code (note that you need to url encode the '/') */
  "merchant/mcc"?: string[];
  /** Filter for merchant id (note that you need to url encode the '/') */
  "merchant/mid"?: string[];
  /** Filter for merchant name (note that you need to url encode the '/') */
  "merchant/name"?: string[];
  /** Filter for postal code (note that you need to url encode the '/') */
  "merchant/postal_code"?: string[];
  /** Filter for source of payment, e.g.: ECOMMERCE */
  "source/method"?: CardsTransactionMethod[];
  /** Filter for authorization status. When an authorization request is received in our system its status is PENDING. Shortly after the status becomes APPROVED or DECLINDED. Finally, the authorization ends in status CLOSED, REVERSED, or EXPIRED. */
  status?: CardsAuthorizationStatus[];
  /** filter for wallet token id, e.g.: 00000000-0000-0000-0000-000000000000 */
  wallet_token_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsAuthorizationOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListCardAuthorizationsData = CardsAuthorization[];

export interface ListCardAuthorizationEventsParams {
  /**
   *
   *     Filter for the approved hold amount. Let's assume an initial authorization request for 50 EUR is received.
   *     Then a new authorization is shown with PENDING as status and the approved amount of 0.00.
   *     After the authorization was approved the amount shows the requested value of -50.00 EUR (please note the signed value).
   *     In case that afterwards an authorization advice of 20 EUR is received, the approved amount of -30.00 EUR is shown.
   *
   *     You can search for authorizations with an hold amount of more than 50 EUR with a filter
   *     value of <=-50.00. Please note the signed value and that you need to url encode special characters like '<', '=', '/', '>', etc.
   *     This means the encoded value for '<=-50.00' is 'amounts%2Fapproved%2Famount=%3C%3D-50.00'.
   *
   */
  "amounts/approved/amount"?: string[];
  /** Filter for approved amount currency, e.g.: EUR, USD (note that you need to url encode special characters like '/', '>', ...) */
  "amounts/approved/currency"?: Currency[];
  /**
   *
   *     Filter for requested amount value, e.g.: <=-50.00 (note that you need to url encode special characters like '/', '>', ...).
   *     This value can be seen as bill amount and when fees are applied then this value can differ from the requested merchant amount.
   *     For an initial authorization request of 50 EUR the requested amount is -50.00 if we do not
   *     take any fees into account. In case that an authorization advice of 20 EUR is received the requested amount is -20.00.
   *
   */
  "amounts/requested/amount"?: string[];
  /** Filter for requested amount currency, e.g.: EUR, USD (note that you need to url encode special characters like '/', '>', ...) */
  "amounts/requested/currency"?: Currency[];
  /**
   *
   *     Filter for requested amount of cash, e.g.: <=-50.00 (note that you need to
   *     url encode special characters like '/', '>', ...). The cashback amount is
   *     included in the approved amount.
   *
   */
  "amounts/requested_cashback/amount"?: string[];
  /**
   *
   *     Filter for requested amount value, e.g.: <=-50.00 (note that you need to url encode special characters like '/', '>', ...).
   *     This value can be seen as transaction value and can differ from the requested amount above
   *     when e.g. certain fees are applied. In case that an authorization advice of 20 EUR is received the requested merchant amount is -20.00.
   *
   */
  "amounts/requested_merchant/amount"?: string[];
  /** Filter for requested amount currency, e.g.: EUR, USD (note that you need to url encode special characters like '/', '>', ...) */
  "amounts/requested_merchant/currency"?: Currency[];
  /** filter for authorization id, e.g.: 00000000-0000-0000-0000-000000000000 */
  authorization_id?: string[];
  /** filter for card id, e.g.: 00000000-0000-0000-0000-000000000000 */
  card_id?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/completed"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/initiated"?: string[];
  /** filter for transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** Fill in parameter description */
  kind?: CardsAuthorizationEventKind[];
  /** Fill in parameter description */
  outcome_reason?: CardsAuthorizationEventOutcomeReason[];
  /** Fill in parameter description */
  status?: CardsAuthorizationEventStatus[];
  /** filter for wallet token id, e.g.: 00000000-0000-0000-0000-000000000000 */
  "wallet/token_id"?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsAuthorizationEventOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListCardAuthorizationEventsData = CardsAuthorizationEvent[];

export interface ListCardHoldersParams {
  /** filter for external reference */
  external_reference?: string[];
  /** filter for card holder id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for identity id, e.g.: 00000000-0000-0000-0000-000000000000 */
  identity_id?: string[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsCardHoldersOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListCardHoldersData = CardsCardHolderListEntry[];

export type CreateCardHolderData = CardsCreatedCardHolder;

export type UpdateCardHolderData = CardsUpdatedCardHolder;

export type CardReplacementData = CardsCreatedReplacementCard;

export interface GetWalletTokensParams {
  /** filter for card id, e.g.: 00000000-0000-0000-0000-000000000000 */
  card_id?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created_at"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/last_updated_at"?: string[];
  /** filter for fulfillment decision, e.g.: DECISION_GREEN */
  "device/kind"?: CardsWalletDeviceType[];
  /** filter for fulfillment decision, e.g.: DECISION_GREEN */
  "fulfillment/decision"?: CardsWalletTokenFulfillmentDecision[];
  /** filter for wallet token id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for wallet token status, e.g.: ACTIVATED */
  status?: CardsWalletTokenStatus[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsWalletTokenOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type GetWalletTokensData = CardsWalletTokenListEntry[];

export type GoogleWebpushProvisioningData = CardsGoogleWebPushProvisioningResponse;

export type CreateAppleProvisioningRequestData = CardsAppleProvisioningRequest;

export type CreateAppleWebpushProvisioningRequestData = CardsAppleWebPushProvisioningRequest;

export type CreateGoogleProvisioningRequestData = CardsGoogleProvisioningRequest;

export type UpdateTokenStatusData = CardsTokenTransitionResponse;

export interface ListCardTransactionsParams {
  /** Filter for total amount spent by customer, e.g. -50.00 EUR (note that you need to url encode special characters like '/', '>', ...). This value can differ from the total merchant amount when fees are applied. In case we receive a full clearing for the hold amount of 50.00 EUR, the value for total amount is -50.00 (not taking any fees into account). */
  "amounts/total/amount"?: string[];
  /** Filter for amount currency in ISO 4217 alphabetic currency code, e.g.: EUR, USD (note that you need to url encode special characters like '/', '>', ...) */
  "amounts/total/currency"?: Currency[];
  /** Filter for merchant amount spent at merchant, e.g. -50.00 (note that you need to url encode special characters like '/', '>', ...). This value can differ from the total amount above when fees are applied. In case we receive a full clearing for the hold amount of 50.00 EUR, the value for total merchant amount is -50.00 (not taking any fees into account). */
  "amounts/total_merchant/amount"?: string[];
  /** Filter for merchant amount currency in ISO 4217 alphabetic currency code, e.g.: EUR, USD (note that you need to url encode special characters like '/', '>', ...) */
  "amounts/total_merchant/currency"?: Currency[];
  /** filter for authorization id, e.g.: 00000000-0000-0000-0000-000000000000 */
  authorization_id?: string[];
  /** filter for card id, e.g.: 00000000-0000-0000-0000-000000000000 */
  card_id?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/completed"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/initiated"?: string[];
  /** filter for transaction id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** Filter for kind of a transaction, e.g.: CAPTURE */
  kind?: CardsTransactionKind[];
  /** Filter for city (note that you need to url encode the '/') */
  "merchant/city"?: string[];
  /** Filter for country code ([ISO 3166 alpha-3](https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes) e.g. DEU, BEL, NLD, LUX, ..., and note that you need to url encode the '/') */
  "merchant/country"?: string[];
  /** Filter for merchant mcc code (note that you need to url encode the '/') */
  "merchant/mcc"?: string[];
  /** Filter for merchant id (note that you need to url encode the '/') */
  "merchant/mid"?: string[];
  /** Filter for merchant name (note that you need to url encode the '/') */
  "merchant/name"?: string[];
  /** Filter for postal code (note that you need to url encode the '/') */
  "merchant/postal_code"?: string[];
  /** Filter for source of payment, e.g.: ECOMMERCE */
  "source/method"?: CardsTransactionMethod[];
  /** Filter for clearing status e.g. PENDING (note that you need to url encode the '/') */
  status?: CardsTransactionStatus[];
  /** filter for wallet token id, e.g.: 00000000-0000-0000-0000-000000000000 */
  wallet_token_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsTransactionOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListCardTransactionsData = CardsTransaction[];

export interface ListVelocityControlsParams {
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/starts_at"?: string[];
  /** filter for velocity control id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for target id, e.g.: 00000000-0000-0000-0000-000000000000 */
  target_id?: string[];
  /** filter for specific target type, e.g.: CARD */
  target_kind?: CardsVelocityControlTargetKind[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  tenant_id?: string[];
  /** filter for specific window type, e.g.: LIFETIME */
  window_kind?: CardsVelocityControlWindowKind[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsVelocityControlsOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListVelocityControlsData = CardsVelocityControlListEntry[];

export type CreateVelocityControlData = CardsCreatedVelocityControl;

export interface ListAppliedVelocityControlsCardParams {
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: CardsAppliedVelocityControlsOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
  /**
   * Id of the target card
   * @format uuid
   */
  id: string;
}

export type ListAppliedVelocityControlsCardData = CardsAppliedVelocityControlListEntry[];

export type UpdateVelocityControlData = CardsCreatedVelocityControl;

export type GetCardData = CardsCardDetails;

export type UpdateCardStatusData = CardsChangeCardStatusResponse;

export type CreateTicketData = CardsCreateDockVaultTicketResponse;

export interface ListTenantsParams {
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/created"?: string[];
  /** Filter for timestamp, e.g.: '>=2000-01-01T15:00:00.00Z', '<2010-01-01T15:00:00.00Z' */
  "datetimes/updated"?: string[];
  /** filter for tenant id, e.g.: 00000000-0000-0000-0000-000000000000 */
  id?: string[];
  /** filter for tenant label */
  label?: string[];
  /** filter for parent id, e.g.: 00000000-0000-0000-0000-000000000000 */
  parent_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: TenantsTenantOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListTenantsData = TenantsTenant[];

export type CreateTenantData = TenantsTenant;

export type UpdateTenantLabelData = TenantsTenant;

export interface ListUsersParams {
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/created"?: string[];
  /** filter for user id, e.g.: `00000000-0000-0000-0000-000000000000` */
  id?: string[];
  /** filter for identity id, e.g.: `00000000-0000-0000-0000-000000000000` */
  identity_id?: string[];
  /** filter for user label */
  label?: string[];
  /** status of user */
  status?: UsersStatus[];
  /** filter for tenant id, e.g.: `00000000-0000-0000-0000-000000000000` */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: UsersUserOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListUsersData = UsersUser[];

export type CreateUserData = UsersUser;

export type CapabilitiesData = UsersUserCapabilities;

export interface ListCryptoKeysParams {
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/created"?: string[];
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/expires"?: string[];
  /** filter for crypto key id, e.g.: `00000000-0000-0000-0000-000000000000` */
  id?: string[];
  /** status of crypto key */
  status?: UsersAuthMethodStatus[];
  /** filter for user id, e.g.: `00000000-0000-0000-0000-000000000000` */
  user_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: UsersCryptoKeyOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListCryptoKeysData = UsersCryptoKey[];

export interface ListDeviceTokensParams {
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/created"?: string[];
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/expires"?: string[];
  /** filter for device token id, e.g.: `00000000-0000-0000-0000-000000000000` */
  id?: string[];
  /** status of device token */
  status?: UsersTokenStatus[];
  /** filter for user id, e.g.: `00000000-0000-0000-0000-000000000000` */
  user_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: UsersDeviceTokenOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListDeviceTokensData = UsersDeviceToken[];

export interface ListOauthAssignmentsParams {
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/created"?: string[];
  /** filter for OAuth assignment id, e.g.: `00000000-0000-0000-0000-000000000000` */
  id?: string[];
  /** filter for assigned oauth provider id, e.g.: `00000000-0000-0000-0000-000000000000` */
  oauth_provider_id?: string[];
  /** status of OAuth assigned */
  status?: UsersAuthMethodStatus[];
  /** filter for assigned user id, e.g.: `00000000-0000-0000-0000-000000000000` */
  user_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: UsersOAuthAssignmentOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListOauthAssignmentsData = UsersOAuthAssignment[];

export interface ListOauthProvidersParams {
  /** Filter for timestamp, e.g.: `>=2000-01-01T15:00:00.00Z`, `<2010-01-01T15:00:00.00Z` */
  "datetimes/created"?: string[];
  /** filter for OAuth provider id, e.g.: `00000000-0000-0000-0000-000000000000` */
  id?: string[];
  /** status of OAuth provider */
  status?: UsersAuthMethodStatus[];
  /** filter for tenant id, e.g.: `00000000-0000-0000-0000-000000000000` */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: UsersOAuthProviderOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListOauthProvidersData = UsersOAuthProvider[];

export type CreateOauthProviderData = UsersOAuthProvider;

export type WhoamiData = UsersWhoAmI;

export type CreateCryptoKeyData = UsersCryptoKey;

export type CreateDeviceTokenData = UsersNewDeviceToken;

export type AssignProviderToUserData = UsersOAuthAssignment;

export type CreateResetTokenData = UsersResetToken;

export interface ListBankTransferInstrumentParams {
  /** Id of the account */
  account_id?: string[];
  /** datetimes of the event */
  "datetimes/created"?: string[];
  /** definition_id of the instrument */
  definition_id?: string[];
  /** external_reference */
  external_reference?: string[];
  /** holder_id of the instrument */
  holder_id?: string[];
  /** iban of the bank transfer instrument */
  iban?: Iban[];
  /** Id of the bank transfer instrument */
  id?: string[];
  /** tenant_id of the instrument */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: BankTransferBankTransferInstrumentOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListBankTransferInstrumentData = BankTransferBankTransferInstrument[];

export type CreateBankTransferInstrumentData = BankTransferBankTransferInstrument;

export interface ListBankTransferHoldersParams {
  /** datetimes of the holder creation */
  "datetimes/created"?: string[];
  /** external_reference of the holder */
  external_reference?: string[];
  /** Id of the holder */
  id?: string[];
  /** Id of the identity */
  identities?: string[];
  /** label of the holder */
  label?: string[];
  /** Id of the tenant */
  tenant_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: BankTransferHolderOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListBankTransferHoldersData = BankTransferHolder[];

export type CreateBankTransferHolderData = BankTransferHolder;

export interface ListBankTransferPaymentsParams {
  /** Id of the payment instrument used for event */
  bank_transfer_id?: string[];
  /** datetimes of the event */
  "datetimes/created"?: string[];
  /** description of the payment */
  description?: string[];
  /** external_reference of the payment */
  external_reference?: string[];
  /** Id of the payment of this event */
  payment_id?: string[];
  /** iban of the receiver */
  "receiver/iban"?: Iban[];
  /** name of the v */
  "receiver/name"?: string[];
  /** amount of the payment */
  "sender_amount/amount"?: string[];
  /** currency of the payment */
  "sender_amount/currency"?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: BankTransferPaymentOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListBankTransferPaymentsData = BankTransferPayment[];

export interface ListBankTransferPaymentEventsParams {
  /** Id of the payment instrument used for event */
  bank_transfer_id?: string[];
  /** datetimes of the event */
  "datetimes/created"?: string[];
  /** the kind of the event */
  kind?: BankTransferPaymentEventStatus[];
  /** Id of the payment of this event */
  payment_id?: string[];
  /** Id of the payment instance of this event */
  payment_instance_id?: string[];
  /** Id of the transaction that this event caused. This is optional beacuse there can be no transaction for a payment yet. */
  transaction_id?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: BankTransferPaymentEventOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListBankTransferPaymentEventsData = BankTransferPaymentEvent[];

export interface ListBankTransferTransactionsParams {
  /** amount */
  amount?: string[];
  /** bank_transfer_id */
  bank_transfer_id?: string[];
  /** currency */
  currency?: string[];
  /** datetimes/created */
  "datetimes/created"?: string[];
  /** end_to_end_id */
  end_to_end_id?: string[];
  /** Id */
  id?: string[];
  /** instruction_id */
  instruction_id?: string[];
  /** kind */
  kind?: BankTransferTransactionKind[];
  /** payment_details */
  payment_details?: string[];
  /** payment_id */
  payment_id?: string[];
  /** payment_instance_id */
  payment_instance_id?: string[];
  /** counterparty address_line1 */
  "payment_system_specific/sepa/counterparty/address_line1"?: string[];
  /** counterparty address_line2 */
  "payment_system_specific/sepa/counterparty/address_line2"?: string[];
  /** payment_system_specific/sepa/counterparty/bic */
  "payment_system_specific/sepa/counterparty/bic"?: string[];
  /** payment_system_specific/sepa/counterparty/country */
  "payment_system_specific/sepa/counterparty/country"?: string[];
  /** counterparty iban */
  "payment_system_specific/sepa/counterparty/iban"?: Iban[];
  /** counterparty name */
  "payment_system_specific/sepa/counterparty/name"?: string[];
  /** payment_system_specific/sepa/kind */
  "payment_system_specific/sepa/kind"?: BankTransferBankTransferTransactionSepaKind[];
  /** party address_line1 */
  "payment_system_specific/sepa/party/address_line1"?: string[];
  /** party address_line2 */
  "payment_system_specific/sepa/party/address_line2"?: string[];
  /** payment_system_specific/sepa/party/bic */
  "payment_system_specific/sepa/party/bic"?: string[];
  /** payment_system_specific/sepa/party/country */
  "payment_system_specific/sepa/party/country"?: string[];
  /** party iban */
  "payment_system_specific/sepa/party/iban"?: Iban[];
  /** party name */
  "payment_system_specific/sepa/party/name"?: string[];
  /** remittance_information */
  remittance_information?: string[];
  /** reversal_of */
  reversal_of?: string[];
  /** If provided, will be used as a starting point for the query. */
  _continuation_token?: ContinuationToken;
  /** Changes an entities order. e.g.: '+id', '-id'. By default, it's sorted from the oldest to the newest entities */
  _order_by?: BankTransferTransactionOrderBy;
  /** Maximum entities in a response. The default value is 30. Max value is 100. */
  _size?: PaginationSize;
}

export type ListBankTransferTransactionsData = BankTransferBankTransferTransaction[];

export type CreateBankTransferPaymentData = BankTransferPayment;
