import { z } from 'zod';
import { UUID_LENGTH } from '@dock/common';
import validationLang from '../lang';

export const requiredUuidValidation = z
    .string({
        required_error: validationLang.REQUIRED,
    })
    .trim()
    .length(UUID_LENGTH, { message: validationLang.GET_LENGTH(UUID_LENGTH) });
